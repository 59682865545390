<template>
  <div style="padding-left: 0px;">
    <span v-text="label"></span>
    <el-popover
      ref="popover"
      v-model="popoverVisible"
      placement="bottom"
      trigger="click"
      popper-class="popbuscar"
    >
      <div style="display: flex;">
        <el-button
          type="text"
          @click="limpiar()"
          icon="el-icon-delete"
          size="small"
          style="width: 40px"
        ></el-button>

        <el-select v-model="buscarInput" size="small">
          <el-option label="A visitar (Todos)" value="AV"></el-option>
          <el-option label="A visitar (Sólo No Visitados)" value="AVNV"></el-option>
          <el-option label="Visitados" value="YV"></el-option>
        </el-select>

        <div style="display: flex">
          <el-button
            style="margin: auto; margin-left: 10px"
            type="primary"
            icon="el-icon-check"
            @click="guardarValor()"
            size="small"
            plain
            circle
          ></el-button>
        </div>
      </div>
      <el-button
        v-if="currentValue == null || currentValue == ''"
        type="text"
        slot="reference"
        style="margin-left: 5px; width: 25px;"
        size="small"
      >
        <span>
          <i class="el-icon-search"></i>
        </span>
      </el-button>
      <el-button
        v-else
        type="primary"
        slot="reference"
        circle
        size="mini"
        style="margin-left: 5px;"
      >
        <span>
          <i class="el-icon-search"></i>
        </span>
      </el-button>
    </el-popover>
  </div>
</template>

<style>
</style>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      buscarInput: null,
      currentValue: null,
      popoverVisible: false,
    };
  },
  created() {
    this.currentValue = this.value;
  },
  methods: {
    guardarValor() {
      this.$emit("input", this.buscarInput);
      this.currentValue = this.buscarInput;
      this.popoverVisible = false;
    },
    limpiar() {
      this.$emit("input", null);
      this.currentValue = null;
      this.popoverVisible = false;
    },
  },
};
</script>